import React from 'react';
import { RichTextInput } from '../../../components/RichTextInput';
import { Button } from '../../../components/buttons';
import { WorkflowStatus } from '@tactiq/model';
import { useSubmitWorkflowConfirmation } from '../../../services/Workflow';
import { FormattedMessage } from 'react-intl';
import { NodeData, WorkflowExecution } from '../../../graphql/operations';
import { ThumbsUp as ConfirmationIcon } from 'lucide-react';

const ConfirmationScreen: React.FC<{
  nodeData: NodeData;
  execution: WorkflowExecution;
}> = ({ nodeData, execution }) => {
  const [content, setContent] = React.useState(nodeData.output.data.prompt);
  const submitYes = useSubmitWorkflowConfirmation();
  const submitNo = useSubmitWorkflowConfirmation();

  const submitHandler = (isYes: boolean) => {
    const fetcher = isYes ? submitYes : submitNo;
    if (fetcher.loading) {
      return;
    }
    return fetcher.request({
      input: {
        executionId: execution.id,
        nodeId: nodeData.id,
        content,
        isYes,
      },
    });
  };

  if (submitYes.error) throw submitYes.error;
  if (submitNo.error) throw submitNo.error;

  return (
    <div className="border-1 flex flex-col rounded-lg border border-slate-200 bg-white">
      <div className="flex items-center gap-3 border-b border-slate-200 p-4">
        <ConfirmationIcon className="size-8 rounded-lg bg-rose-50 p-1.5 text-rose-600 border border-rose-400/25" />
        <h2 className="font-semibold">
          <FormattedMessage defaultMessage="Confirmation" id="Pswssl" />
        </h2>
      </div>

      <h2 className="px-4 pt-2 text-sm font-semibold">
        <FormattedMessage defaultMessage="Workflow output" id="eJ3OG5" />
      </h2>

      <div className="mx-4 mt-2 h-64 overflow-y-auto rounded-lg border border-slate-200 px-4 py-2">
        <RichTextInput
          value={content}
          isEditable={true}
          onChange={(value) => setContent(value)}
          disableCitations
        />
      </div>
      <div className="flex flex-row justify-center gap-2 px-4 py-3">
        <Button
          variant="secondaryOutline"
          loading={submitNo.loading}
          onClick={(event) => {
            event.preventDefault();
            submitHandler(false);
          }}
          fullWidth
        >
          <FormattedMessage defaultMessage="Decline" id="pvtgR2" />
        </Button>
        <Button
          loading={submitYes.loading}
          onClick={(event) => {
            event.preventDefault();
            submitHandler(true);
          }}
          fullWidth
        >
          <FormattedMessage defaultMessage="Accept" id="sjzLbX" />
        </Button>
      </div>
    </div>
  );
};

export const WorkflowConfirmation: React.FC<{
  execution: WorkflowExecution;
}> = (props) => {
  const { execution } = props;

  return (
    <>
      {execution.nodeData
        .filter((_) => _.status === WorkflowStatus.WAITING_FOR_CONFIRMATION)
        .map((nodeData) => (
          <ConfirmationScreen
            key={nodeData.id}
            nodeData={nodeData}
            execution={execution}
          />
        ))}
    </>
  );
};
