import React, { ReactElement } from 'react';
import { WorkflowExecution, WorkflowStatus } from '../../graphql/operations';
import { formatDistanceStrict } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { WorkflowStatusIcon } from './WorkflowStatusIcon';
import { kMeeting, workflowItemExecution } from '../../helpers/routes';
import { WorkflowConfirmation } from '../Transcripts/view/WorkflowConfirmation';
import { Link } from 'react-router-dom';
import { cx } from '../../helpers/utils';
import { ExternalLink } from 'lucide-react';
import { Tooltip } from '../../components/Tooltip';

/**
 * Render a workflow execution in a list
 */
export function WorkflowExecutionItemRow({
  active,
  isEditorView,
  execution,
}: {
  isEditorView?: boolean;
  active?: boolean;
  execution: WorkflowExecution;
}): ReactElement {
  const { formatTime, formatDate } = useIntl();
  const { updatedAt } = execution;
  const classes = cx(
    'group rounded-lg transition-colors p-3 duration-200 overflow-hidden flex-shrink-0',
    active
      ? 'bg-slate-200 hover:bg-slate-200'
      : 'bg-slate-50 hover:bg-slate-100'
  );
  const exactTime = `${formatDate(updatedAt)} ${formatTime(updatedAt)}`;
  const relativeTime = (
    <span title={exactTime}>
      {formatDistanceStrict(new Date(updatedAt), new Date(), {
        addSuffix: true,
      })}
    </span>
  );
  const title = (
    <div
      key={execution.id}
      className={
        'relative flex w-full flex-shrink-0 cursor-pointer items-center gap-1 rounded-md transition-all duration-1000'
      }
    >
      <div className="flex-shrink-0">
        <WorkflowStatusIcon status={execution.status} />
      </div>
      <div className="mr-auto truncate text-sm">
        {isEditorView ? relativeTime : execution.workflowName}
      </div>

      {!isEditorView && (
        <div className="ml-2 flex-shrink-[10000] truncate text-xs text-slate-500 opacity-0 group-hover:opacity-100">
          <span>{relativeTime}</span>
        </div>
      )}

      {isEditorView && (
        <Tooltip
          arrow
          title={
            <FormattedMessage defaultMessage="View transcript" id="tS1eQb" />
          }
        >
          <Link
            className="-m-3 p-3 hover:bg-slate-200"
            to={kMeeting.replace(':id', execution.meetingId)}
          >
            <ExternalLink
              className="opacity-0 group-hover:opacity-100"
              size="20px"
            />
          </Link>
        </Tooltip>
      )}
    </div>
  );

  return (
    <Link
      className={classes}
      to={workflowItemExecution
        .replace(':id', execution.workflowId)
        .replace(':executionId', execution.id)}
    >
      {title}
      {execution.status === WorkflowStatus.WAITING_FOR_CONFIRMATION && (
        <div className="mt-3 flex flex-col gap-2">
          <WorkflowConfirmation execution={execution} />
        </div>
      )}
    </Link>
  );
}
