import React, { ReactElement, useEffect, useState } from 'react';
import { ModalDialog } from '../../components/modals';
import {
  useWorkflowCreate,
  useWorkflowList,
  useWorkflowRun,
} from '../../services/Workflow';
import { FormattedMessage } from 'react-intl';
import {
  ArrowLeft,
  ArrowUpRight,
  Library,
  List,
  Plus,
  Users,
} from 'lucide-react';
import { Button } from '../../components/buttons';
import { WorkflowsType } from '../../graphql/operations';
import { WorkflowPreview } from './WorkflowEditor';
import { useNavigate } from 'react-router';
import { RunWorkflowButton } from './RunWorkflowButton';
import { DuplicateWorkflowButton } from './DuplicateWorkflowButton';
import { cx } from '../../helpers/utils';
import {
  trackWorkflowBrowsed,
  trackWorkflowPreview,
} from '../../helpers/analytics';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '../../components/ToggleButtonGroup';
import { Chip } from '../../components/Chips';
import { Skeleton } from '../../components/Skeleton';
import { Tooltip } from '../../components/Tooltip';
import tactiqLogoMark from '../../img/tactic-logomark.svg';
import { useSendAutomationEvent } from '../../services/Email';
import { AutomationEventName } from '@tactiq/model';

/**
 * View the workflow discover page from a modal
 */
export function WorkflowDiscoveryModal(props: {
  open: boolean;
  setOpen: (next: boolean) => void;
  meetingId?: string;
  defaultType?:
    | WorkflowsType.TEAM
    | WorkflowsType.TACTIQ
    | WorkflowsType.ALL_DISCOVER;
}): ReactElement {
  const { open, setOpen, meetingId, defaultType } = props;
  const [workflowId, setWorkflowId] = useState<string>();
  const sendAutomationEvent = useSendAutomationEvent();

  useEffect(() => {
    if (workflowId) {
      trackWorkflowPreview({ workflowId, source: 'modal' });
    }
  }, [workflowId]);

  useEffect(() => {
    if (open) {
      sendAutomationEvent.request({
        input: { type: AutomationEventName.workflow_browsed },
      });
      trackWorkflowBrowsed({ source: 'modal' });
    }
  }, [open]);

  return (
    <ModalDialog
      open={open}
      bgColor="raised"
      size="large"
      onClose={() => setOpen(false)}
    >
      <WorkflowDiscovery
        workflowId={workflowId}
        setWorkflowId={setWorkflowId}
        meetingId={meetingId}
        setMeetingId={() => {}}
        onSuccess={() => setOpen(false)}
        heightClassName="h-[60vh]"
        selectedType={defaultType}
      />
    </ModalDialog>
  );
}

/**
 * Gives people a way to preview workflows without duplicating or running them.
 */
export function WorkflowDiscovery(props: {
  workflowId: string | undefined;
  setWorkflowId: (next: string | undefined) => void;
  meetingId?: string;
  setMeetingId: (next?: string) => void;
  onSuccess: (next: { meetingId: string; executionId: string }) => void;
  heightClassName?: string;
  selectedType?:
    | WorkflowsType.TEAM
    | WorkflowsType.TACTIQ
    | WorkflowsType.ALL_DISCOVER;
}): ReactElement {
  const {
    onSuccess,
    meetingId,
    heightClassName,
    workflowId,
    setWorkflowId,
    selectedType,
  } = props;
  const tactiqWorkflowList = useWorkflowList({
    input: { type: WorkflowsType.TACTIQ },
  });
  const teamWorkflowList = useWorkflowList({
    input: { type: WorkflowsType.TEAM },
  });
  const allWorkflowList = useWorkflowList({
    input: { type: WorkflowsType.ALL_DISCOVER },
  });
  const [type, setType] = useState<
    WorkflowsType.ALL_DISCOVER | WorkflowsType.TACTIQ | WorkflowsType.TEAM
  >(selectedType ?? WorkflowsType.ALL_DISCOVER);

  const tabs = [
    {
      value: WorkflowsType.TACTIQ,
      label: <FormattedMessage defaultMessage="Recommended" id="VKfWR3" />,
    },
  ];
  if (teamWorkflowList.data?.workflows?.length) {
    tabs.unshift({
      value: WorkflowsType.TEAM,
      label: <FormattedMessage defaultMessage="Team" id="wsUmh9" />,
    });
  }
  tabs.unshift({
    value: WorkflowsType.ALL_DISCOVER,
    label: <FormattedMessage defaultMessage="All" id="zQvVDJ" />,
  });

  useEffect(() => {
    // Switch to all tab if there are no team workflows
    if (
      selectedType === WorkflowsType.TEAM &&
      !teamWorkflowList.loading &&
      !teamWorkflowList.data?.workflows?.length
    ) {
      setType(WorkflowsType.ALL_DISCOVER);
    }
  }, [selectedType, teamWorkflowList]);

  const workflows = {
    [WorkflowsType.ALL_DISCOVER]: allWorkflowList.data?.workflows,
    [WorkflowsType.TACTIQ]: tactiqWorkflowList.data?.workflows,
    [WorkflowsType.TEAM]: teamWorkflowList.data?.workflows,
  }[type];

  const preview = workflows?.find((workflow) => workflow.id === workflowId);
  const workflowRun = useWorkflowRun('discovery');
  if (workflowRun.error) throw workflowRun.error;

  if (preview) {
    return (
      <div
        className={cx(
          'flex flex-col overflow-hidden rounded-lg',
          heightClassName ?? 'h-full'
        )}
      >
        <div className="mb-3 flex items-center justify-between gap-x-6">
          <Button
            variant="secondaryOutline"
            onClick={() => setWorkflowId(undefined)}
            startIcon={<ArrowLeft size="1rem" />}
          >
            <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
          </Button>
          <div className="text-lg font-semibold">{preview.name}</div>
          <div className="flex flex-row gap-2">
            <DuplicateWorkflowButton
              id={preview.id}
              variant={preview.canRun ? 'secondaryOutline' : 'filled'}
            />
            {preview.canRun ? (
              <RunWorkflowButton
                meetingId={meetingId}
                workflow={preview}
                onSuccess={onSuccess}
              />
            ) : null}
          </div>
        </div>
        <div className="flex-grow">
          <WorkflowPreview
            nodes={preview.definition.nodes}
            edges={preview.definition.edges}
          />
        </div>
      </div>
    );
  }

  const isLoading =
    teamWorkflowList.loading ||
    tactiqWorkflowList.loading ||
    allWorkflowList.loading;

  return (
    <div className={cx(heightClassName, 'flex flex-col')}>
      <div className="mb-8 flex flex-col justify-between gap-y-4 md:mb-8 md:flex-row">
        <div className="flex items-center gap-2">
          <Library />
          <div className="text-2xl font-bold tracking-tight">
            <FormattedMessage defaultMessage="Workflow Templates" id="SdX349" />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          {isLoading ? (
            <Skeleton className="h-8" />
          ) : (
            <ToggleButtonGroup
              value={type}
              onChange={setType}
              style="disconnected"
            >
              {tabs.map(({ value, label }) => (
                <ToggleButton key={value} value={value}>
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </div>
      </div>
      <div className="flex w-full flex-col gap-10 overflow-auto @container">
        <div className="flex flex-col gap-y-8">
          <BlankCard />
          <div className="flex items-center justify-center gap-x-6">
            <div className="h-px w-full bg-slate-200"></div>
            <div className="shrink-0 text-sm font-medium text-slate-500">
              <div>
                <FormattedMessage
                  defaultMessage="or try a suggested template"
                  id="TZiggU"
                />
              </div>
            </div>
            <div className="h-px w-full bg-slate-200"></div>
          </div>
          <div
            className={cx(
              'grid w-full auto-rows-min grid-cols-1 gap-4 overflow-auto pt-2 @md:grid-cols-2 @2xl:grid-cols-3 @7xl:grid-cols-4'
            )}
          >
            {isLoading ? (
              <Skeleton count={6} className="h-40" />
            ) : (
              workflows?.map((workflow) => {
                return (
                  <div
                    key={workflow.id}
                    onClick={() => setWorkflowId(workflow.id)}
                    className="flex cursor-pointer flex-col justify-between gap-4 rounded-2xl border border-slate-200 bg-white p-5 text-lg leading-5 transition-all duration-200 will-change-transform hover:-translate-y-0.5 hover:shadow-lg hover:shadow-slate-500/10"
                  >
                    <div className="flex flex-col gap-y-6">
                      <div className="flex items-center justify-between">
                        {workflow.tactiq ? (
                          <img src={tactiqLogoMark} alt="" />
                        ) : (
                          <Users size={32} className="text-purple-500" />
                        )}
                        <div className="flex gap-x-3">
                          <div className="">
                            {workflow.integrations.map((intg) => (
                              <Chip key={intg} color="slate">
                                {intg}
                              </Chip>
                            ))}
                          </div>
                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              <FormattedMessage
                                defaultMessage="{count} steps"
                                values={{
                                  count: workflow.definition.nodes.length,
                                }}
                                id="2GQj+0"
                              />
                            }
                          >
                            <div className="flex items-center gap-x-2">
                              <List size={16} className="text-slate-400" />
                              <div className="inline-flex bg-white text-sm font-semibold text-slate-600">
                                {workflow.definition.nodes.length}
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </div>

                      <div className="flex items-start">
                        <div className="text-md mb-auto line-clamp-2 font-semibold leading-6 text-slate-700">
                          {workflow.name}
                        </div>
                      </div>
                    </div>
                    <Button
                      className="mt-3"
                      variant="secondaryOutline"
                      size="small"
                      fullWidth
                      endIcon={<ArrowUpRight size="1rem" />}
                      onClick={() => setWorkflowId(workflow.id)}
                    >
                      <FormattedMessage defaultMessage="Preview" id="TJo5E6" />
                    </Button>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const BlankCard: React.FC = () => {
  const { data, request, loading } = useWorkflowCreate();
  const isSuccess = Boolean(data && !loading);
  const navigate = useNavigate();
  const id = data?.createWorkflow.id;

  useEffect(() => {
    if (isSuccess) {
      navigate(`/workflows/${id}`);
    }
  }, [id, isSuccess, navigate]);

  return (
    <div
      className="tq-dashed-border group flex min-h-20 cursor-pointer items-center justify-center rounded-2xl bg-white px-5 text-lg leading-5 transition-colors hover:bg-slate-50"
      onClick={request}
    >
      <div className="relative flex items-center justify-center gap-x-1.5">
        <Plus
          size={18}
          strokeWidth={2.5}
          className="absolute -right-6 text-slate-600 opacity-0 transition-all group-hover:rotate-90 group-hover:opacity-100"
        />
        <div className="text-base font-semibold text-slate-500 group-hover:text-slate-600">
          <FormattedMessage
            defaultMessage="Start with a blank workflow"
            id="9Dd2PR"
          />
        </div>
      </div>
      {/* <Button
        loading={loading}
        className=""
        variant="secondaryOutline"
        size="small"
        endIcon={<ArrowUpRight size="1rem" />}
      >
        <FormattedMessage defaultMessage="Start" id="mOFG3K" />
      </Button> */}
    </div>
  );
};
