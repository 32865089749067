import { useReactFlow, getIncomers } from '@xyflow/react';
import { WorkflowNode, WorkflowNodeProps } from './BaseNode';
import { nodeTypes, useNodeTypeToName } from '.';
import type { Completion, CompletionContext } from '@codemirror/autocomplete';
import type { EditorState } from '@codemirror/state';
import { useWorkflowId } from '../WorkflowIdContext';
import { useWorkflowItem } from '../../../services/Workflow';
import { WorkflowTemplateContext } from '@tactiq/model';
import { IntlShape, useIntl } from 'react-intl';

type AnyShape = ObjectShape<any> | ArrayShape | BasicType;

export interface ObjectShape<T> {
  type: 'object';
  description?: string;
  fields: Record<keyof T, AnyShape>;
}

export interface BasicType {
  type: 'string' | 'number' | 'boolean';
  description?: string;
}

export interface ArrayShape {
  type: 'array';
  description?: string;
  items: AnyShape;
}

const getUserProperties = (
  intl: IntlShape
): ObjectShape<WorkflowTemplateContext['user']> => ({
  type: 'object',
  fields: {
    email: {
      type: 'string',
      description: intl.formatMessage({
        defaultMessage: 'The email of the user',
      }),
    },
    name: {
      type: 'string',
      description: intl.formatMessage({
        defaultMessage: 'The name of the user',
      }),
    },
  },
});

const getMeetingProperties = (
  intl: IntlShape
): ObjectShape<WorkflowTemplateContext['meeting']> => ({
  type: 'object',
  fields: {
    title: {
      type: 'string',
      description: intl.formatMessage({
        defaultMessage: 'The title of the meeting',
      }),
    },
    url: {
      type: 'string',
      description: intl.formatMessage({
        defaultMessage: 'A link to the meeting in Tactiq.io',
      }),
    },
    labels: {
      type: 'array',
      description: intl.formatMessage({
        defaultMessage: 'The labels of the meeting',
      }),
      items: {
        type: 'string',
        description: intl.formatMessage({
          defaultMessage: 'the name of the label',
        }),
      },
    },
    participantEmails: {
      type: 'array',
      description: 'A list of emails for each participant.',
      items: {
        type: 'string',
        description: 'the email of the participant',
      },
    },
    participantNames: {
      type: 'array',
      description: 'A list of names for each participant.',
      items: {
        type: 'string',
        description: 'the name of the participant',
      },
    },
    participants: {
      type: 'array',
      description: intl.formatMessage({
        defaultMessage: 'The participants of the meeting',
      }),
      items: {
        type: 'string',
        description: intl.formatMessage({
          defaultMessage: 'The name of the participant',
        }),
      },
    },
  },
});

export function staticCompletion<T extends ObjectShape<any>>(
  shape: T,
  path: string[]
): Completion[] {
  let currentShape: AnyShape = shape;
  for (const currentPath of path) {
    if (currentShape.type === 'object') {
      currentShape = currentShape.fields[currentPath];
    } else if (currentShape.type === 'array') {
      currentShape = currentShape.items;
    }
    if (!currentShape) {
      return [];
    }
  }
  if (currentShape.type === 'object') {
    return Object.entries(currentShape.fields).map(([label, field]) => ({
      label,
      type: field.type,
      detail: field.description,
    }));
  } else if (currentShape.type === 'array') {
    return [
      {
        label: '0',
        type: currentShape.items.type,
        detail: currentShape.items.description,
      },
      {
        label: 'length',
        type: currentShape.items.type,
        detail: currentShape.items.description,
      },
    ];
  }
  return [];
}

export interface AutocompleteProvider {
  autocompleteProperties: (node: WorkflowNode, path: string[]) => Completion[];
}

export function nodeAutocompleteProperties(
  node: WorkflowNode,
  path: string[]
): Completion[] {
  const nodeType = node.type;
  if (!nodeType) {
    return [];
  }
  const nodeTypeProperties = (
    nodeTypes[nodeType] as unknown as AutocompleteProvider
  ).autocompleteProperties;
  if (!nodeTypeProperties) {
    return [];
  }
  return nodeTypeProperties(node, path);
}

const emptyAutocomplete = {
  variables: [],
  properties: () => [],
};

export function useAutocomplete(node: WorkflowNodeProps): {
  variables: Completion[];
  properties: (
    path: readonly string[],
    state: EditorState,
    context: CompletionContext
  ) => Completion[];
} {
  const { workflowId } = useWorkflowId();
  const { data } = useWorkflowItem({ id: workflowId });
  const { getNodes, getEdges, getNode } = useReactFlow<WorkflowNode>();
  const nodeTypeToName = useNodeTypeToName();
  const intl = useIntl();

  // performance optimization: don't calculate autocomplete for non-selected nodes
  if (!node.selected || node.dragging) {
    return emptyAutocomplete;
  }

  const recentExecutionData = data?.workflow?.recentExecution?.nodeData;

  const parentNodes = new Set<string>();

  const edges = getEdges();
  const nodes = getNodes();
  const stack: string[] = [node.id];

  while (stack.length > 0) {
    const currentId = stack.pop();
    if (!currentId) {
      break;
    }
    if (parentNodes.has(currentId)) {
      continue;
    }
    parentNodes.add(currentId);
    const incomingNodes = getIncomers({ id: currentId }, nodes, edges).filter(
      (n) => n.type !== 'StartNode'
    );
    stack.push(...incomingNodes.map((n) => n.id));
  }

  const stepVariables: Completion[] = Array.from(parentNodes)
    .filter((nodeId) => node.id !== nodeId)
    .map((id) => {
      const autocompleteNode = getNode(id);
      if (!autocompleteNode) {
        throw new Error(`Node with id ${id} not found`);
      }
      const nodeType = autocompleteNode.type;
      if (!nodeType) {
        throw new Error(`Node with id ${id} has no type`);
      }
      const info = recentExecutionData?.find((d) => d.id === id)?.output;
      return {
        label: id,
        type: `step_${nodeType}`,
        detail: autocompleteNode.data.displayName ?? nodeTypeToName[nodeType],
        info: info
          ? `Recent output: \n\n ${JSON.stringify(info).substring(0, 300)}...`
          : undefined,
      };
    });

  // don't suggest `input` variable if there more than one incoming edge
  const currentNodeIncomers = getIncomers({ id: node.id }, nodes, edges).filter(
    (ii) => ii.type !== 'StartNode'
  );
  const hasInputVariable = currentNodeIncomers.length === 1;

  const properties = (
    path: readonly string[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: EditorState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context: CompletionContext
  ): Completion[] => {
    if (path.length < 1) {
      return [];
    }
    if (path[0] === 'input') {
      return nodeAutocompleteProperties(currentNodeIncomers[0], path.slice(1));
    } else if (parentNodes.has(path[0])) {
      const node = getNode(path[0]);
      if (!node) {
        return [];
      }
      return nodeAutocompleteProperties(node, path.slice(1));
    } else if (path[0] === 'meeting') {
      return staticCompletion(getMeetingProperties(intl), path.slice(1));
    } else if (path[0] === 'user') {
      return staticCompletion(getUserProperties(intl), path.slice(1));
    }
    return [];
  };

  return {
    variables: [
      ...(hasInputVariable
        ? [
            {
              label: 'input',
              type: `step_${currentNodeIncomers[0].type}`,
              detail: currentNodeIncomers[0].data.displayName,
            },
          ]
        : []),
      {
        label: 'meeting',
      },
      {
        label: 'user',
      },
      ...stepVariables,
    ],
    properties,
  };
}

/**
 * This is very hacked together for now. Will see what people say about the
 * dropdown and amend it as needed. JSON output support is intentionally omitted
 */
export function getFlatVariableList(options: {
  variables: Completion[];
  isArray: boolean;
  intl: IntlShape;
  nodeType?: WorkflowNodeProps['type'];
}): Array<{
  group?: string;
  templates: Array<{ label: string; template: string }>;
}> {
  const { variables, isArray, nodeType, intl } = options;
  const join = isArray ? '' : `| join: ', ' `;
  return [
    {
      group: intl.formatMessage({
        defaultMessage: 'Outputs from previous steps',
      }),
      templates: variables
        .filter(
          (ii) =>
            ii.label !== 'meeting' &&
            ii.label !== 'user' &&
            ii.label !== 'input'
        )
        .map((v) => ({
          label: v.label,
          template: `{{ ${v.label}.output }}`,
        })),
    },
    {
      group: intl.formatMessage({
        defaultMessage: 'Meeting Details',
      }),
      templates: Object.entries(getMeetingProperties(intl).fields).flatMap(
        ([key, value]) =>
          getMeetingDetailPropertyTemplate(key, join, intl, nodeType) ?? {
            label: value.description || key,
            template:
              value.type === 'array'
                ? `{{ meeting.${key} ${join}}}`
                : `{{ meeting.${key} }}`,
          }
      ),
    },
    {
      group: intl.formatMessage({
        defaultMessage: 'User Details (you)',
      }),
      templates: Object.entries(getUserProperties(intl).fields).flatMap(
        ([key, value]) => ({
          label: value.description || key,
          template: `{{ user.${key} }}`,
        })
      ),
    },
  ];
}

function getMeetingDetailPropertyTemplate(
  key: string,
  join: string,
  intl: IntlShape,
  nodeType?: WorkflowNodeProps['type']
): { label: string; template: string }[] | undefined {
  if (nodeType === 'Condition' || nodeType === 'Confirmation') {
    switch (key) {
      // participants is deprecated but still present for legacy templates
      case 'participants':
        return [];

      case 'participantNames':
        return [
          {
            label: intl.formatMessage({
              defaultMessage: 'count participants',
              id: 'JKhjor',
            }),
            template: '{{ meeting.participantNames.size > 2 }}',
          },
        ];

      case 'participantEmails':
        return [
          {
            label: intl.formatMessage({
              defaultMessage: 'check for a participant',
            }),
            template: `{{ meeting.participantEmails contains 'team@tactiq.io' }}`,
          },
        ];

      case 'labels':
        return [
          {
            label: intl.formatMessage({
              defaultMessage: 'check for a label',
            }),
            template: `{{ meeting.labels contains 'meeting label' }}`,
          },
        ];
      case 'title':
        return [
          {
            label: intl.formatMessage(
              {
                defaultMessage: 'check {key}',
              },
              { key }
            ),
            template: `{{ meeting.${key} contains 'abc' }}`,
          },
        ];
      case 'url':
        return [];
      default:
        return undefined;
    }
  }

  switch (key) {
    // participants is deprecated but still present for legacy templates
    case 'participants':
      return [];

    case 'participantNames':
      return [
        {
          label: intl.formatMessage({ defaultMessage: 'participant names' }),
          template: `{{ meeting.participantNames ${join}}}`,
        },
      ];

    case 'participantEmails':
      return [
        {
          label: intl.formatMessage({
            defaultMessage: 'participant emails',
          }),
          template: `{{ meeting.participantEmails ${join}}}`,
        },
      ];

    case 'title':
      return [
        {
          label: intl.formatMessage({
            defaultMessage: 'title',
          }),
          template: `{{ meeting.${key} }}`,
        },
      ];

    case 'url':
      return [
        {
          label: intl.formatMessage({
            defaultMessage: 'A link to the meeting in Tactiq.io',
          }),
          template: `{{ meeting.${key} }}`,
        },
      ];

    default:
      return undefined;
  }
}
